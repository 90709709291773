
  import { defineComponent, ref, onMounted, computed } from "vue";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  // import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
  import AddSocialModal from "@/components/modals/forms/AddSocialModal.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { Actions } from '@/store/enums/StoreEnums' 
  import { useStore } from "vuex";
  export default defineComponent({
    name: "social-listing",
    components: {
      Datatable,
      AddSocialModal,
      // ExportCustomerModal,
      // AddCustomerModal,
    },
    setup() {
      const store = useStore()
      const checkedCustomers = ref([]);
      const tableHeader = ref([
        // {
        //   key: "checkbox",
        //   sortable: false,
        // },
        {
          name: "Tarmoq nomi",
          key: "name",
          sortable: true,
        },
        {
          name: "Icon",
          key: "icon",
          sortable: true,
        },
        {
          name: "Link",
          key: "link",
          sortable: true,
        },
        {
          name: "Status",
          key: "status",
          sortable: true,
        },
        {
          name: "",
          key: "actions",
        },
      ]);
  

      const dataTable = computed(()=>{
        return store.getters.alSocials
      }) 

  
      onMounted(async() => {
        setCurrentPageBreadcrumbs("Socials Listing", ["Section"]);
        await store.dispatch(Actions.ALL_SOCIALS, {params:{limit: 10}});
      });


      const perPage = async(val:number)=>{
        await store.dispatch(Actions.ALL_SOCIALS, {params:{limit: val}});
      }

      const edit = ref<boolean>(false)

      const itemId = ref<string>("")

      const editItem = (item: string)=>{
        edit.value = true;
        itemId.value = item
        
      }
      const changeStatus = async(item: string, status: number)=>{
        await store.dispatch(Actions.CHANGE_SOCIAL_STATUS, {item, status});
      }
  
      const deleteFewCustomers = () => {
        checkedCustomers.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCustomers.value.length = 0;
      };
  
      const deleteCustomer = async(id:string) => {
        await store.dispatch(Actions.DEL_SOCIAL, id);
      };
  
      const search = ref<string>("");
      const searchItems = async() => {
        await store.dispatch(Actions.ALL_TAGS, {params:{name: search.value}});
      };
      

      return {
        tableHeader,
        deleteCustomer,
        search,
        searchItems,
        checkedCustomers,
        deleteFewCustomers,
        dataTable, edit, editItem, itemId, perPage, changeStatus
      };
    },
  });
  